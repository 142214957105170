import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3caacc40&"
var script = {}
import style0 from "./assets/js/common.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Ffonts.googleapis.com%2Fcss%3Ffamily%3DNunito%3A300%2C300i%2C400%2C600%2C800&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports