import Vue from 'vue'
import Router from 'vue-router'
// import Home from '../views/Home.vue'
// import lanhu_chanpin from '../views/lanhu_chanpin/index.vue'
// import lanhu_guanyuwomen from '../views/lanhu_guanyuwomen/index.vue'
// import lanhu_zhaoxiannashi from '../views/lanhu_zhaoxiannashi/index.vue'
// import lanhu_dongtaizixunxiangqing from '../views/lanhu_dongtaizixunxiangqing/index.vue'
// import lanhu_dongtaizixun from '../views/lanhu_dongtaizixun/index.vue'
// import lanhu_shouye from '../views/lanhu_shouye/index.vue'
// import lanhu_guanwangchanpinchanpinmokuai from '../views/lanhu_guanwangchanpinchanpinmokuai/index.vue'
// import lanhu_guanwangchanpintantiaoye from '../views/lanhu_guanwangchanpintantiaoye/index.vue'

Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default new Router({
        routes: [{
            path: '/',
            name: 'home',
            component: resolve => require(['../views/Home'], resolve),

            // redirect: "/HomeContainer",
            children: [{
                    path: '/',
                    component: resolve => require(['../views/HomeContainer'], resolve),
                },
                {
                    path: '/InformationList',
                    component: resolve => require(['../views/information/InformationList'], resolve),
                },
                {
                    path: '/InformationDetails',
                    component: resolve => require(['../views/information/InformationDetails'], resolve),
                },
                {
                    path: '/RecruitInfo',
                    component: resolve => require(['../views/recruit/RecruitInfo'], resolve),
                },
                {
                    path: '/AboutUs',
                    component: resolve => require(['../views/aboutUs/AboutUs'], resolve),
                },
                {
                    path: '/CompartmentDesign',
                    component: resolve => require(['../views/compartmentDesign/CompartmentDesign'], resolve),
                },
            ]

        }, ],



        scrollBehavior(to, from, saveTop) {
            if (saveTop) {
                return saveTop;
            } else {
                return { x: 0, y: 0 }
            }
        }
    })
    // Vue.use(VueRouter)

// const routes = [{
//         // path: '/',
//         // name: 'Home',
//         // component: Home,
//         // redirect: "/HomeContainer",

//         path: '/',
//         name: 'Home',
//         component: resolve => require(['../views/Home'], resolve),

//         redirect: "/HomeContainer",
//     },
//     {
//         path: '/lanhu_chanpin',
//         name: 'lanhu_chanpin',
//         component: lanhu_chanpin
//     },
//     {
//         path: '/lanhu_guanyuwomen',
//         name: 'lanhu_guanyuwomen',
//         component: lanhu_guanyuwomen
//     },
//     {
//         path: '/lanhu_zhaoxiannashi',
//         name: 'lanhu_zhaoxiannashi',
//         component: lanhu_zhaoxiannashi
//     },
//     {
//         path: '/lanhu_dongtaizixunxiangqing',
//         name: 'lanhu_dongtaizixunxiangqing',
//         component: lanhu_dongtaizixunxiangqing
//     },
//     {
//         path: '/lanhu_dongtaizixun',
//         name: 'lanhu_dongtaizixun',
//         component: lanhu_dongtaizixun
//     },
//     {
//         path: '/lanhu_shouye',
//         name: 'lanhu_shouye',
//         component: lanhu_shouye
//     },
//     {
//         path: '/lanhu_guanwangchanpinchanpinmokuai',
//         name: 'lanhu_guanwangchanpinchanpinmokuai',
//         component: lanhu_guanwangchanpinchanpinmokuai
//     },
//     {
//         path: '/lanhu_guanwangchanpintantiaoye',
//         name: 'lanhu_guanwangchanpintantiaoye',
//         component: lanhu_guanwangchanpintantiaoye
//     }
// ]

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

// export default router